<template>
  <div>
    <header class="mb-auto" style="background-color: rgb(0, 153, 255)">
      <nav>
        <span class="navbar-brand mb-0 h1" style="color: white"
          >インテリジェントヘルスケア担当者ログインページ</span
        >
      </nav>
    </header>
    <div class="login">
      <h3>
        <img
          class="logo"
          src="@/img/logo.png"
          alt="インテリジェントヘルスケア ストレスチェックApp"
        />
      </h3>
      <!-- <h3>ログインページ</h3> -->
      <div
        v-show="
          $store.state.common.sIsLoading || $store.state.common.sDataLoading
        "
      >
        Loading...
      </div>
      <div
        v-show="
          !$store.state.common.sIsLoading && !$store.state.common.sDataLoading
        "
      >
        <div class="uk-flex uk-flex-center">
          <div class="uk-container">
            <div class="uk-text-center" uk-grid>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted"
                      >メールアドレス</label
                    >
                  </div>
                  <input class="uk-input" type="text" v-model="email" />
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted"
                      >パスワード</label
                    >
                  </div>
                  <input class="uk-input" :type="vInputType" v-model="pass" />
                  <span uk-icon="icon: unlock" @click="doSwitchPassword"></span>
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <button
                    :disabled="$store.state.common.sServerProcessing"
                    class="uk-button uk-button-secondary uk-width-1-1"
                    type="button"
                    @click="sendInfo"
                  >
                    ログイン
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p style="color: red">
          <b>{{ vMessage }}</b>
        </p>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      email: "",
      pass: "",
      state: false,
      flg: false,
      tmp: null,
      do_something: true,
      vInputType: "password",
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "ログイン";
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = true;
    if (sessionStorage.getItem("email_i")) {
      this.$store.state.email = sessionStorage.getItem("email_i");
      this.$store.state.pass = sessionStorage.getItem("pass_i");
      this.sendInfo(1);
    } else {
      this.$store.state.common.sDataLoading = false;
    }
  },
  computed: {
    inputType: function () {
      return this.isChecked ? "text" : "password";
    },
  },
  methods: {
    doSwitchPassword() {
      if (this.vInputType == "password") {
        this.vInputType = "text";
      } else if (this.vInputType == "text") {
        this.vInputType = "password";
      }
    },
    // sha256: async function(str) {
    //   // Convert string to ArrayBuffer
    //   let buff = new Uint8Array([].map.call(str, (c) => c.charCodeAt(0))).buffer;
    //   // Calculate digest
    //   let digest = await crypto.subtle.digest('SHA-256', buff);
    //   // Convert ArrayBuffer to hex string
    //   // (from: https://stackoverflow.com/a/40031979)
    //   return [].map.call(new Uint8Array(digest), x => ('00' + x.toString(16)).slice(-2)).join('');
    // },
    sendInfo(mode = 0) {
      if (!this.do_something) return;
      this.do_something = false;
      if (mode == 1) {
        this.$store.commit("common/doStartServerProcess");
        this.$axios
          .post("/intelligent/login", {
            email: this.$store.state.email,
            pass: this.$store.state.pass,
          })
          .then(
            function (response) {
              if (response.data.state == false) {
                this.state = response.data.state;
                this.$store.state.login_status_i = response.data.state;
                alert("メールアドレスかパスワードが正しくありません ");
              } else {
                this.state = response.data.state;
                this.$store.commit("setLogin_i", {
                  email: this.$store.state.email,
                  pass: this.$store.state.pass,
                  status: response.data.state,
                });
                sessionStorage.setItem("email_i", this.$store.state.email);
                sessionStorage.setItem("pass_i", this.$store.state.pass);
                this.$store.commit("common/doEndServerProcess");
                this.$router.replace("/intelligent-menu");
              }
              this.$store.commit("common/doEndServerProcess");
              this.do_something = true;
              this.$store.state.common.sDataLoading = false;
            }.bind(this)
          )
          .catch(
            function (error) {
              this.state = false;
              console.log(error);
              this.$store.commit("common/doEndServerProcess");
              this.do_something = true;
              this.$store.state.common.sDataLoading = false;
            }.bind(this)
          );
      } else if (!this.email) {
        alert("メールアドレスを入力してください");
        this.$store.commit("common/doEndServerProcess");
        this.do_something = true;
      } else if (!this.pass) {
        alert("パスワードを入力してください");
        this.$store.commit("common/doEndServerProcess");
        this.do_something = true;
      } else {
        this.$store.commit("common/doStartServerProcess");
        let createHash = require("sha256-uint8array").createHash;
        this.tmp = createHash().update(this.pass).digest("hex");
        console.log(this.tmp);
        if (this.email.length > 0 && this.pass.length > 0) {
          this.$axios
            .post("/intelligent/login", {
              email: this.email,
              pass: this.tmp,
            })
            .then(
              function (response) {
                if (response.data.state == false) {
                  this.state = response.data.state;
                  this.$store.commit("setLogout_i", {});
                  this.$store.commit("common/doEndServerProcess");
                  alert("メールアドレスかパスワードが正しくありません ");
                } else {
                  this.state = response.data.state;
                  this.$store.commit("setLogin_i", {
                    email: this.email,
                    pass: this.tmp,
                    status: response.data.state,
                  });
                  sessionStorage.setItem("email_i", this.$store.state.email);
                  sessionStorage.setItem("pass_i", this.$store.state.pass);
                  console.log(this.$store.state.login_status_i);
                  this.$store.commit("common/doEndServerProcess");
                  this.$router.replace("/intelligent-menu");
                }
                this.$store.commit("common/doEndServerProcess");
                this.do_something = true;
              }.bind(this)
            )
            .catch(
              function (error) {
                this.state = false;
                this.$store.commit("common/doEndServerProcess");
                this.do_something = true;
                console.log(error);
              }.bind(this)
            );
        } else {
          this.$store.commit("common/doEndServerProcess");
          alert("内容が入力されていません");
          this.do_something = true;
        }
      }
    },
  },
  pushHideButton() {
    console.log("flg = " + this.flg);
    this.flg = !this.flg;
  },
};
</script>

<style scoped>
#textPassword {
  border: none;
  /* デフォルトの枠線を消す */
}

#fieldPassword {
  border-width: thin;
  border-style: solid;
  width: 200px;
}

dl dt {
  text-align: right;
  width: 165px;
  padding: 10px 0;
  float: left;
  clear: both;
}

dl dd {
  padding: 10px 0;
}
p {
  display: inline;
}
</style>
